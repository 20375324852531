export class RouteConstants {
  static readonly DASHBOARD_ROUTE = 'dashboard';
  static readonly ORGANIZATION_DASHBOARD_ROUTE = 'organization-dashboard';
  static readonly CLIENTS_ROUTE = 'clients';
  static readonly CLIENTS_PROFILE_ROUTE = 'profile';
  static readonly CLIENTS_OVERVIEW_ROUTE = 'overview';
  static readonly CLIENTS_NOTES_ROUTE = 'notes';
  static readonly CLIENTS_GOALS_ROUTE = 'goals';
  static readonly CLIENTS_METRICS_ROUTE = 'metrics';
  static readonly CLIENTS_GOALS_METRICS_ROUTE = 'goals-metrics';
  static readonly CLIENTS_EDIT_GOALS_ROUTE = 'goals/edit';
  static readonly COACHES_ROUTE = 'coaches';
  static readonly TEAMS_ROUTE = 'teams';
  static readonly EDIT_COACH_ROUTE = 'coaches/edit-coach';
  static readonly EXERCISES_ROUTE = 'exercises';
  static readonly WORKOUTS_ROUTE = 'workouts';
  static readonly WORKOUT_CATEGORIES_ROUTE = 'workout-categories';
  static readonly WORKOUTS_ADD_ROUTE = 'workout/add';
  static readonly SCREENINGS_ROUTE = 'screenings';
  static readonly CHAT_ROUTE = 'chat';
  static readonly NOTIFICATION_ROUTE = 'notification';
  static readonly PROFILE_ROUTE = 'profile';
  static readonly CATALOG_ROUTE = 'catalog';
  static readonly LOGIN_ROUTE = 'login';
  static readonly CHANGE_PASSWORD_ROUTE = 'change-password';
  static readonly CHANGE_EMAIL_ROUTE = 'change-email';
  static readonly FORGOT_PASSWORD_ROUTE = 'forgot-password';
  static readonly SIGN_OUT_ROUTE = 'sign-out';
  static readonly SCHEDULER_PROTOTYPE_ROUTE = 'scheduler';
  static readonly INGREDIENTS_ROUTE = 'ingredients';
  static readonly MEAL_PLAN_ROUTE = 'meal-plan';
  static readonly DAILY_MEALS_ROUTE = 'daily-meals';
  static readonly MEALS_ROUTE = 'meals';
  static readonly WORKOUT_PROGRAMS_ROUTE = 'workout-programs';
  static readonly DAILY_WORKOUTS_ROUTE = 'daily-workouts';
  static readonly REFLECTIONS_ROUTE = 'reflections';
  static readonly ADD_REFLECTION_ROUTE = 'add-reflection';
  static readonly EDIT_REFLECTION_ROUTE = 'edit-reflection';
  static readonly VIEW_REFLECTION_ROUTE = 'view-reflection';
  static readonly HABITS_ROUTE = 'habits';
  static readonly QUIZZES_ROUTE = 'quizzes';
  static readonly ADD_QUIZ_ROUTE = 'add-quiz';
  static readonly EDIT_QUIZ_ROUTE = 'edit-quiz';
  static readonly VIEW_QUIZ_ROUTE = 'view-quiz';
  static readonly CLIENTS_QUIZ_ROUTE = 'quiz';
  static readonly CONFIGURE_ROUTE = 'configure/translation';
  static readonly TRANSLATION_INGREDIENTS_ROUTE = 'physique/meals/ingredients';
  static readonly TRANSLATION_MEALS_ROUTE = 'physique/meals/meals';
  static readonly ORGANIZATIONS_ROUTE = 'organizations';
  static readonly ORGANIZATION_TEAMS_ROUTE = 'teams';
  static readonly ORGANIZATION_ADMINS_ROUTE = 'admins';
  static readonly ORGANIZATION_PROFILE_ROUTE = 'profile';
  static readonly ORGANIZATION_CLIENTS_ROUTE = 'clients';
  static readonly ORGANIZATION_COACHES_ROUTE = 'coaches';
  static readonly SETTINGS_ROUTE = 'settings';
  static readonly SETTINGS_HABIT_CATEGORIES_ROUTE = 'habits/habit-categories';
  static readonly SETTINGS_GOALS_METRICS_ROUTE = 'goals-metrics';
  static readonly SETTINGS_MEALS_ROUTE = 'meals';
  static readonly SETTINGS_WORKOUT_EXERCISE_ROUTE = 'workout-exercise';
  static readonly WORKOUT_EXERCISE_ROUTE = 'workout-exercise';
  static readonly EXERCISE_CATEGORIES_ROUTE = 'exercise-categories';
  static readonly PATTERNS_ROUTE = 'patterns';
  static readonly EQUIPMENT_ROUTE = 'equipment';
  static readonly PLANES_ROUTE = 'planes';
  static readonly PRIMARY_MUSCLES_ROUTE = 'primary-muscles';
  static readonly UNITS_ROUTE = 'units';
  static readonly TAGS_ROUTE = 'tags';
  static readonly METRICS_ROUTE = 'metrics';
  static readonly METRIC_CATEGORIES_ROUTE = 'metric-categories';
  static readonly GROUPS_ROUTE = 'groups';
  static readonly QUIZ_ROUTE = 'quiz';
  static readonly QUESTIONS_ROUTE = 'questions';
  static readonly SCHEDULER_ROUTE = 'scheduler';
}
